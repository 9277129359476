.chat_style{
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    margin-bottom: 30px;
}

.chat_style > p {
    font-size: 18px;
    font-weight: 600;
    color: #333;
}

.chat_style > span {
    font-size: 12px;
    color: #333;
}