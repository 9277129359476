/* Mobile responciove */
@media only screen and (max-width: 570px) {
  .container {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .d-none {
    display: flex !important;
  }
  .mb-none {
    display: none;
  }
  .filter__container {
    flex-direction: column;
  }
  .filter__left {
    width: 100% !important;
  }
  .filter__right {
    width: 100% !important;
  }
  .home_categories {
    flex-direction: column;
  }
  .cate__box {
    width: 100% !important;
    margin: 0px !important;
    margin-bottom: 30px !important;
  }
  .Footer__menu ul {
    display: unset !important;
  }
  .mobileFixBar {
    padding: 10px 0;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .fixed-bottom {
    bottom: 0;
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030;
  }
  .btn-left {
    width: 47%;
  }
  .btn-right {
    width: 52%;
  }
  .footBtn {
    margin: 0;
  }
  .MuiBottomNavigation-root {
    background-color: unset !important;
  }
  .post-ads-section {
    padding-right: unset !important;
    padding-left: unset !important;
  }
  .steps .stpe-box {
    font-size: 12px !important;
    width: 25px !important;
    height: 25px !important;
  }
  .steps .stpe-box p {
    padding-top: 4px !important;
  }
  .steps span {
    padding-left: 10px;
    font-size: 12px !important;
  }
  .steps-line {
    display: none !important;
  }
  .box-pack {
    width: 100% !important;
  }
  .result_img {
    width: 100% !important;
    height: 225px !important;
  }
  .result_content {
    width: 100% !important;
  }
  .result_data {
    padding: 10px 15px !important;
  }
  .result_data .title {
    font-size: 14px !important;
    line-height: 22px !important;
    max-height: 45px !important;
    min-height: 45px !important;
  }
  .result_data .discription {
    font-size: 12px !important;
    line-height: 16px !important;
    max-height: 33px !important;
    min-height: 33px !important;
    margin-bottom: 5px !important;
    text-align: justify;
  }
  .result_data .tags {
    font-size: 12px !important;
  }
  .result_row {
    max-height: unset !important;
    min-height: unset !important;
    flex-direction: column;
  }
  .last_row {
    display: unset !important;
  }
  .mg-serach {
    margin-left: unset !important;
    margin-top: 20px;
  }
  .btn-size {
    width: 100%;
  }

  .profile-section {
    display: unset !important;
  }
  .profilr-box {
    width: unset !important;
  }
  .pro-body {
    display: unset !important;
    text-align: center !important;
  }
  .link-edit {
    display: unset !important;
  }
  .credits-box {
    width: unset !important;
  }
  .single_left {
    width: unset !important;
  }
  .single_right {
    margin-top: 0px !important;
    width: unset !important;
    /* display: none !important; */
  }
  .contact_foot_btnRow {
    flex-direction: column;
  }
  .single_container {
    flex-direction: column;
  }
  .box-tab {
    padding: 15px 3px !important;
  }
  .credit-box {
    padding: 10px 10px !important;
    height: auto !important;
    display: block !important;
  }
  .credit-box .name {
    font-size: 16px !important;
    width: 100% !important;
  }
  .credit-box .options {
    font-size: 14px !important;
    width: 100% !important;
    text-align: end !important;
    margin-top: -25px !important;
  }
  .credit-box .discount {
    width: 100% !important;
    justify-content: flex-end !important;
  }
  .credit-box .discounts {
    font-size: 10px !important;
    padding: 3px 3px !important;
    border-radius: 5px !important;
    margin-bottom: 10px;
  }
  .credit-box .price {
    width: 100% !important;
  }
  .credit-box .price span {
    font-size: 14px !important;
  }
  .credit-box .price p {
    font-size: 18px !important;
  }
  .cal-2 {
    flex-direction: column !important;
  }
  .cal-2-1-with {
    width: 100% !important;
    margin-bottom: 20px !important;
  }
  .cal-2-2-with {
    width: 100% !important;
  }
  .premium-bar {
    display: block !important;
  }
  .premium-bar-img {
    width: 40% !important;
  }
  .premium-bar-text {
    width: 100% !important;
    margin-bottom: 10px;
  }
  .total-priceBar {
    margin-bottom: 15px;
  }
  .premium-bar-btn {
    width: 100% !important;
  }
  .profile-edit-box {
    width: auto !important;
  }
  .pro-img-edit {
    width: 100% !important;
  }
  .pro-img-edit img {
    width: 150px !important;
  }
  .pro-data-edit {
    width: auto !important;
  }
  .pro-data-edit {
    width: auto !important;
    padding: 10px;
  }
  .cart-icon img {
    width: 30px !important;
  }
  .basket-txt p {
    font-size: 15px !important;
  }
  .steps {
    padding: unset !important;
  }
  .packge-item {
    align-items: unset !important;
    flex-direction: column;
  }
  .top-icon img {
    width: 60px !important;
  }
  .item-description {
    width: 100% !important;
  }
  .item-price {
    width: 100% !important;
  }
  .item-price p {
    font-size: 16px !important;
    margin-top: 10px;
  }
  .tot-item {
    align-items: unset !important;
    flex-direction: column;
  }
  .tot-description {
    width: 100% !important;
    text-align: unset !important;
  }
  .tot-description p {
    font-size: 16px !important;
  }
  .tot-price {
    width: 100% !important;
  }
  .tot-price p {
    font-size: 18px !important;
  }
  .option-row {
    padding: 10px 10px !important;
  }
  .MuiFormControlLabel-root {
    margin-right: unset !important;
  }
  .MuiBox-root {
    padding: 10px !important;
  }
  .pro-result_row {
    max-height: unset !important;
    min-height: unset !important;
    flex-direction: column;
  }
  .pro-result_img {
    width: 100% !important;
  }
  .pro-result_img img {
    height: 225px !important;
  }
  .pro-result_content {
    width: 100% !important;
  }
  .pro-result_data {
    padding: 5px 15px !important;
  }
  .pro-result_data .pro-titledata {
    font-size: 14px !important;
  }
  .result_img img {
    height: 225px !important;
  }
  .result_data .btnss {
    justify-content: center !important;
    margin-top: 0px !important;
  }
  .Footer__bar {
    flex-direction: column;
  }
  .Home_Banner {
    height: 160px !important;
  }
  .Home_Banner > p {
    font-size: 20px !important;
    text-align: center;
  }
  .Home_Banner > span {
    font-size: 12px !important;
  }
  .filter__container {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-left: unset !important;
    margin-right: unset !important;
    border-radius: 20px !important;
    box-shadow: 0 1px 10px -1px #00000026;
    margin-top: -35px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    flex-direction: column;
  }
  .filter__inputs {
    flex: unset !important;
    width: 100% !important;
    margin-bottom: 10px;
  }
  .mg-search {
    width: 100%;
    margin-left: unset !important;
    margin-right: unset !important;
    margin-top: 15px;
  }
  .dashboard_banner {
    display: flex;
    height: unset !important;
    padding: 5px !important;
  }
  .pro-img img {
    width: 80px !important;
    height: 80px !important;
  }
  .pro-data {
    padding-left: unset !important;
  }
  .pro_name {
    font-size: 16px !important;
  }
  .pro_other {
    font-size: 10px !important;
  }
  .dashboard_menuBar {
    justify-content: center;
  }
  .header__logo__img img {
    width: 180px !important;
  }
  .header__container {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .dashboard_bannerRight {
    flex-direction: column;
  }
  .pro-result_data .pro-btnss {
    flex-direction: column;
  }
  .ads_ft_btn {
    font-size: 10px !important;
  }
  .filter_row1 {
    flex-direction: column;
    margin-bottom: 3px !important;
  }
  .filter_row2 {
    flex-direction: column;
  }
  .header__logo_sec {
    width: unset !important;
  }
  .easy_row {
    flex-direction: column !important;
  }
  .easy_box {
    width: unset !important;
  }
  .why_clean_list {
    margin-bottom: unset !important;
    flex-direction: column;
  }
  .why_clean_list > .item_row {
    width: 100% !important;
    margin-bottom: 10px;
  }

  .home_bt_section2 {
    width: unset !important;
  }

  .home_bt_section2 > h2 {
    font-size: 20px !important;
  }
  .package_box {
    margin-bottom: 20px;
  }
  .packages_row {
    flex-direction: column;
  }
  .dio_body {
    width: auto !important;
  }
  .MuiDialog-paper.MuiDialog-paperScrollPaper {
    width: 100% !important;
  }

  .booking_page {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .contact_us {
    flex-direction: column;
  }
}
