.profile-section {
  display: flex;
}

.profilr-box {
  width: 50%;
  margin: 10px;
  border-radius: 7px;
  box-shadow: 0 0 5px 0px #00000021;
}
.credits-box {
  width: 50%;
  margin: 10px;
  border-radius: 7px;
  box-shadow: 0 0 5px 0px #00000021;
}

.pro-title {
  background-color: #eaeaea;
  border-radius: 7px 7px 0 0;
}
.pro-title p {
  padding: 7px 15px;
  font-weight: 700;
  color: #008464;
}

.pro-body {
  padding: 5px 15px;
  display: flex;
  align-items: center;
}

.pro-img img {
  width: 110px;
  border-radius: 50%;
  height: 110px;
  border-radius: 50%;
  object-fit: cover;
}

.pro-data {
  padding-left: 15px;
}
.pro_other {
  font-size: 13px;
  color: #fff;
  line-height: 1.5;
}

.pro_name {
  font-size: 20px;
  font-weight: 600;
}

.c-icon img {
  width: 80%;
}

.c-name p {
  font-size: 14px;
  font-weight: 600;
  color: #6c6c6c;
}

.c-value p {
  font-size: 16px;
  font-weight: 700;
  color: #be206b;
}

.pro-body-credit {
  padding: 10px 15px 5px 15px;
  display: flex;
  align-items: center;
}

.c-icon {
  width: 15%;
}

.c-name {
  width: 35%;
}
.c-value {
  width: 50%;
  text-align: center;
}

.pro-btn {
  text-align: end;
  padding: 0 15px;
}

.profilr-box-emty {
  width: 50%;
  margin: 10px;
}

.buy-credits-section {
  padding-top: 40px;
  padding-bottom: 40px;
}

.small-txt {
  font-size: 12px;
  color: #737373;
}

.credit-title {
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;
}

.credit-icon img {
  width: 25px;
  margin-right: 10px;
}

.credit-txt p {
  font-size: 14px;
  font-weight: 600;
  color: #be206b;
}

.credit-list ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.credit-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid gainsboro;
  padding: 10px 20px;
  cursor: pointer;
  margin-bottom: 20px;
  height: 60px;
  background-color: white;
  border-radius: 5px;
}

.credit-box .name {
  font-size: 18px;
  font-weight: 600;
  width: 30%;
}
.credit-box .options {
  font-size: 16px;
  width: 30%;
  text-align: center;
}
.credit-box .discount {
  width: 20%;
  display: flex;
  justify-content: center;
}
.credit-box .price {
  width: 20%;
  text-align: end;
}
.credit-box .discounts {
  font-size: 10px;
  background-color: #27c56e;
  padding: 3px 10px;
  border-radius: 10px;
  color: white;
  font-weight: 600;
}
.credit-box .price span {
  font-size: 14px;
  color: #909090;
  font-weight: 600;
}

.credit-box .price p {
  font-size: 20px;
  color: #484848;
  font-weight: 700;
}

.credit-btn {
  text-align: end;
}

.tab-style {
  background-color: #484848 !important;
}

.tab-btn {
  font-weight: 700 !important;
}

.tot-ads p {
  font-size: 14px;
  color: #909090;
  font-weight: 600;
}

.profile-edit-box {
  width: 100%;
  margin: 10px;
  border-radius: 7px;
  box-shadow: 0 0 5px 0px #00000021;
  background-color: #FFFFFF;
}

.pro-img-edit {
  width: 40%;
  text-align: center;
}

.pro-img-edit img {
  width: 200px;
  border-radius: 50%;
}

.pro-data-edit {
  width: 100%;
  margin-top: 20px;
}

.profile__filds {
  margin-bottom: 20px;
}
.profile__btn {
  display: flex;
  text-align: right;
  justify-content: flex-end;
}

.pro-result_row {
  display: flex;
  flex: 1;
  margin-bottom: 30px;
  box-shadow: 0px 0px 7px -1px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  max-height: 185px;
  min-height: 185px;
  background-color: #fff;
}

.pro-result_img {
  width: 20%;
  /* height: 215px; */
}

.pro-result_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  height: 185px;
}
.pro-result_content {
  width: 80%;
}

.pro-result_btn {
  width: 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
  background-color: #bbbbbb7d;
  border-radius: 0 10px 10px 0;
}

.btnz {
  padding: 17px 0;
  background-color: #448bc682;
}

.pro-result_data {
  padding: 5px 20px;
}

.pro-result_data .pro-titledata {
  font-size: 16px;
  font-weight: 600;
  color: #be206b;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 23px;
  max-height: 50px;
  min-height: 50px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 5px;
}

.pro-result_data .pro-discription {
  font-size: 12px;
  color: #545454;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 20px;
  max-height: 42px;
  min-height: 42px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 5px;
}

.pro-result_data .pro-tags {
  font-size: 12px;
  color: #404040;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 20px;
  max-height: 20px;
  min-height: 20px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-bottom: 3px;
}

.pro-result_data .pro-btnss {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.pro-result_data .pro-btnss2 {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.pro-btnss-right > .MuiIconButton-colorPrimary{
  font-size:  12px;
}

.primary__btn_outline-profile {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  border: 0px solid #be206b !important;
  color: #be206b !important;
  background: linear-gradient(to bottom, #ffffff 0%, #ffffff 100%) !important;
  /* margin-bottom: 1rem !important; */
  font-weight: 600 !important;
}

.ads-edit {
  background-color: rgba(0, 0, 0, 0.04) !important;
  color: #448bc6 !important;
}

.pro-exdate p {
  font-size: 12px;
  color: #737373;
  font-weight: 600;
}

.pro-exdate .promo {
  font-size: 12px;
  color: #484848;
  font-weight: 600;
  align-items: center;
  display: flex;
}

.time-active {
  color: #008464 !important;
}
.time-Inactive {
  color: red !important;
}
.time-icon {
  margin-right: 5px !important;
}

.swal2-styled:focus {
  box-shadow: unset !important;
}

.dashboard_banner {
  display: flex;
  height: 150px;

  align-items: center;
  justify-content: center;
  justify-content: space-between;
  padding: 20px;
}

.dashboard_bannerLeft {
  /* flex: 0.5; */
  color: #fff;
}

.dashboard_bannerRight {
  /* flex: 0.5; */
  display: flex;
  justify-content: flex-end;
}

.dashboard_creditBox {
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 5px;
  width: 90px;
  height: 60px;
  justify-content: center;
  flex-direction: column;
  margin: 5px;
  box-shadow: 0 0 10px 3px #00000026;
}

.dashboard_creditBox > .MuiSvgIcon-root {
  font-size: 20px;
  color: rgb(133, 133, 133);
}

.dashboard_creditBox > p {
  font-size: 25px;
  font-weight: 700;
  color: rgb(51, 51, 51);
}

.dashboard_creditBox > span {
  font-size: 12px;
  font-weight: 400;
  color: rgb(133, 133, 133);
}

.dashboard_bg {
  /* background-color: #008464; */
  background-image: url("../img/Triangles-Background--Desktop.d07fac9c.webp"),
    linear-gradient(to bottom, #0880e6, #075497);
}

.dashboard_menuBar {
  display: flex;
  background-color: #014377;
}

.pro_imgBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.pro_imgBox > p {
  font-size: 10px;
  text-align: center;
}

.pro_imgPlace > img {
  width: 160px;
  border-radius: 50%;
  height: 160px;
  border-radius: 50%;
  object-fit: cover;
}
