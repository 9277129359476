.filter__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding-top: 20px;
  padding-bottom: 20px;
  justify-content: space-between;
  /* flex: 1 1; */
  margin-left: 50px;
  margin-right: 50px;
  border-radius: 10px;
  box-shadow: 0 1px 10px -1px #00000026;
  margin-top: -50px;
  padding-left: 40px;
  padding-right: 40px;
}

.filter__select {
  width: 100%;
}

.filter__inputs {
  /* flex : 0.32; */
  width: 33%;
  margin-right: 10px;
}

/* .mg-left{
    margin: 0px 0 0 10px !important;
} */

.mg-search{
    margin-left: 10px;
    margin-right: 10px;
}

.btn-size {
  margin: 0px !important;
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}

.filter_row1 {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
}

.filter_row2 {
    display: flex;
    width: 100%;
  }
