.Footer__container {
  display: flex;
  align-items: center;
  background-color: #252525;
}

.Footer__bar {
  display: flex;
  flex: 1;
  padding-top: 40px;
  padding-bottom: 40px;
}

.Footer__menu {
  flex: 0.25;
  padding: 5px 10px;
}

.foot_title {
  color: #6db8ff;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

.Footer__social {
  flex: 0.25;
  padding: 5px 10px;
}

.Footer__menu ul {
  padding: 0;
  list-style: none;
}

.Footer__menu ul li {
  margin: 3px 0;
}
.Footer__menu ul li a {
  color: white !important;
  font-size: 13px;
  padding: 0 0px;
  display: flex;
  align-items: center;
}

.Footer__social ul {
  padding: 0;
  list-style: none;
  display: flex;
  margin-top: 10px;
}

.twitter__icon {
  background-color: #55acee !important;
  color: #fff !important;
  width: 38px;
  height: 38px;
  position: relative;
  z-index: 1;
  vertical-align: middle;
  display: inline-block;
  overflow: hidden;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  margin: 10px 5px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  padding: 0;
  cursor: pointer;
}
.instagram__icon {
  background-color: #2e5e86 !important;
  color: #fff !important;
  width: 38px;
  height: 38px;
  position: relative;
  z-index: 1;
  vertical-align: middle;
  display: inline-block;
  overflow: hidden;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  margin: 10px 5px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  padding: 0;
  cursor: pointer;
}

.twitter__icon i {
  font-size: 1.25rem;
  line-height: 38px;
  display: inline-block;
  width: inherit;
  text-align: center;
  color: #fff;
}
.instagram__icon i {
  font-size: 1.25rem;
  line-height: 38px;
  display: inline-block;
  width: inherit;
  text-align: center;
  color: #fff;
}

.Footer__container_sub {
  display: flex;
  align-items: center;
  background-color: #07579c;
}

.Footer__subBar {
  display: flex;
  flex: 1;
  padding-top: 5px;
  align-items: center;
  justify-content: center;
  padding-bottom: 5px;
}

.Footer__subBar > p {
  margin: 0 !important;
  color: #fff;
  font-size: 12px;
}

.Footer__longText {
  flex: 0.25;
  padding: 5px 10px;
}
.Footer__contact {
  flex: 0.25;
  padding: 5px 10px;
}
.Footer__longText > p {
  margin: 0 !important;
  color: #fff;
  font-size: 12px;
  text-align: justify;
}

.Footer__longText > img {
  width: 230px;
  margin-bottom: 10px;
}


.contact_item{
    display: flex;
    /* align-items: center; */
    margin-bottom: 10px;
}

.contact_item > .MuiSvgIcon-root{
    color: #fff;
    font-size: 20px;
}

.contact_item > a{
    text-decoration: none;
    color: #fff;
    font-size: 14px;
    padding-left: 10px;
}

.contact_item > p{
  color: #fff;
  font-size: 14px;
  padding-left: 10px;
}