.home_container {
  display: flex;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 20px;
  /* justify-content: space-between; */
  flex-direction: column;
}

.home_title {
  width: 100%;
  text-align: center;
}

.home_title h2 {
  font-size: 1.8rem;
  font-weight: 500 !important;
  margin-bottom: 7px;
  color: #484848;
}

.home_title p {
  font-weight: 300;
  font-size: 16px;
  margin-bottom: 32px;
  color: #484848;
}
.home_categories {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  /* justify-content: space-between; */
  margin-bottom: 30px;
}

.cate__box {
  width: 31%;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 15px -2px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin: 10px;
  background-color: #fff;
}
.cate__img img {
  width: 100%;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
}

.cate__img {
  display: flex;
  height: 230px;
  background-size: cover;
  background-position: center center;
  align-content: flex-end;
  align-items: flex-end;
  padding: 10px 15px;
  border-radius: 10px;
}

.box_img1 {
  background-image: linear-gradient(
      to bottom,
      rgba(8, 25, 43, 0),
      rgba(8, 25, 43, 0.6)
    ),
    url("../img/001.jpg");
}

.box_img2 {
  background-image: linear-gradient(
      to bottom,
      rgba(8, 25, 43, 0),
      rgba(8, 25, 43, 0.6)
    ),
    url("../img/002.jpg");
}

.box_img3 {
  background-image: linear-gradient(
      to bottom,
      rgba(8, 25, 43, 0),
      rgba(8, 25, 43, 0.6)
    ),
    url("../img/003.jpg");
}

.box_img4 {
  background-image: linear-gradient(
      to bottom,
      rgba(8, 25, 43, 0),
      rgba(8, 25, 43, 0.6)
    ),
    url("../img/4_home.jpeg");
}

.box_img5 {
  background-image: linear-gradient(
      to bottom,
      rgba(8, 25, 43, 0),
      rgba(8, 25, 43, 0.6)
    ),
    url("../img/5_home.jpeg");
}

.box_img6 {
  background-image: linear-gradient(
      to bottom,
      rgba(8, 25, 43, 0),
      rgba(8, 25, 43, 0.6)
    ),
    url("../img/6_home.webp");
}

.box_img7 {
  background-image: linear-gradient(
      to bottom,
      rgba(8, 25, 43, 0),
      rgba(8, 25, 43, 0.6)
    ),
    url("../img/7_home.jpg");
}

.bb_txt {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
}

.cate__discription {
  padding: 0.7rem;
  padding-top: 0.5rem;
}
.cate__listItem {
  padding: 0 0.7rem;
  padding-bottom: 15px;
}
.cate__title {
  background: linear-gradient(to bottom, #05d4a2 0%, #008464 100%);
  /* background-color: #008464; */
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  padding: 10px;
  text-align: center;
  margin-bottom: 0px !important;
  margin-top: -6px;
}

.cate__discription p {
  font-weight: 400;
  font-size: 12px;
  color: #545454;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 18px;
  max-height: 55px;
  min-height: 55px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 0px;
}

.home_ul {
  padding-left: 0;
  list-style: none;
}

.home_ul > li {
  display: inline;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
  line-height: 28px;
  font-size: 16px;
}
.home_discriptions {
  display: flex;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 30px;
}
.home_discriptions p {
  font-size: 12px;
  text-align: center;
  line-height: 1.5;
  color: #747373;
}

.home_tabs {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 30px;
  margin-top: 30px;
}

.home_tab_title {
  width: 100%;
  text-align: center;
}
.home_tab_title h2 {
  font-size: 1.8rem;
  font-weight: 500 !important;
  margin-bottom: 20px;
  margin-top: 20px;
  color: #484848;
}

.Home_Banner {
  display: flex;
  height: 200px;
  background-image: linear-gradient(
      to bottom,
      rgba(8, 25, 43, 0.15),
      rgba(8, 25, 43, 0.85)
    ),
    url("../img/green_bg.jpeg");
  background-size: cover;
  background-position: center center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.Home_Banner > p {
  font-size: 30px;
  color: #fff;
  font-weight: 700;
}

.Home_Banner > span {
  font-size: 16px;
  color: #fff;
  font-weight: 300;
}

.home_bt_section {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  text-align: center;
  margin-bottom: 40px;
}

.home_bt_section > h2 {
  color: #484848;
}

.home_bt_section > p {
  color: #484848;
  margin-bottom: 30px;
  font-size: 12px;
}

.easy_row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.easy_box {
  background-color: white;
  width: 29%;
  padding: 15px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
}

.easy_box > .no {
  font-size: 18px;
  font-weight: 700;
  color: #484848;
  border: 2px solid #095ba2;
  width: 30px;
  border-radius: 50%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.easy_box > .title {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
  color: #484848;
}

.easy_box > .description {
  font-size: 14px;
  color: #747373;
  line-height: 1.5;
}

.why_bg {
  background-color: white;
  padding: 10px;
  border-radius: 10px;
}

.why_clean_list {
  list-style: none;
  padding-left: 0;
  display: flex;
  margin-bottom: 20px;
}

.why_clean_list > .item_row {
  display: flex;
  width: 33%;
}

.why_clean_list > .item_row > p {
  padding-left: 15px;
}

.why_clean_list > .item_row > .MuiSvgIcon-root {
  color: #095ba2;
}

.home_bt_section2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  margin-bottom: 40px;
  background-color: white;
  padding: 10px;
  border-radius: 10px;
}

.home_bt_section2 > h2 {
  color: #484848;
  margin-bottom: 10px;
}

.home_bt_section2 > p {
  color: #484848;
  margin-bottom: 30px;
  font-size: 14px;
  line-height: 1.5;
}

.home_bt_section2 > h3 {
  color: #095ba2;
  margin-bottom: 10px;
}

.table_cost {
  margin-bottom: 20px;
}

.table_cost,
th,
td {
  border: 1px solid #484848;
  border-collapse: collapse;
}

.table_cost > thead > tr > th {
  background-color: #001935;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  padding: 10px;
  text-align: left;
}

.table_cost > tbody > tr > td {
  color: #484848;
  font-size: 14px;
  padding: 10px;
}


.hm_main_title {
  color: #484848;
  margin-bottom: 10px;
  font-size: 18px;
}

.faq_txt{
  color: #484848;
  font-size: 14px;
}