.result_container {
  display: flex;
  margin-top: 20px;
  margin-bottom: 40px;
  flex-direction: column;
 
}

.result_row {
    display: flex;
    flex: 1;
    margin-bottom: 30px;
    box-shadow: 0px 0px 7px -1px rgba(0,0,0,0.2);
    border-radius: 10px;
    max-height: 195px;
    min-height: 195px;
    background-color: #fff;
}

.result_img {
    width: 20%;
    height: 195px;
}

.result_img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}
.result_content {
    width: 80%;
}

.result_data{
    padding: 10px 20px;
}

.result_data .title{
    font-size: 16px;
    font-weight: 600;
    color: #be206b;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 25px;
    max-height: 50px;
    min-height: 50px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 5px;
}

.result_data .discription {
    font-size: 12px;
    color: #545454;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 20px;
    max-height: 38px;
    min-height: 38px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 0px;
}

.result_data .tags {
    font-size: 14px;
    color: #404040;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 20px;
    max-height: 20px;
    min-height: 20px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin-bottom: 7px;
}
.result_data .btnss{
    text-align: right;
    display: flex;
    justify-content: flex-end;
    /* margin-top: -10px; */
  
}
.icon-bt{
    font-weight: 600 !important;
}


.single_container {
    display: flex;
    margin-top: 40px;
    margin-bottom: 40px;
   
  }
  .single_left{
    width: 70%;
  }

  .single_right{
    width: 30%;
    margin-top: 100px;
    align-items: center;
    display: flex;
    justify-content: flex-end;
  }

  .single_address p{
    font-size: 12px;
    color: #666666;
    margin-bottom: 20px;
  }

  .single_title h2{
    font-size: 20px;
    color: #3f4257;
    margin-bottom: 15px;
    overflow-wrap: break-word;
    hyphens: auto;
  }

  .single_disc{
    font-size: 16px;
    color: #666666;
  }
  .result_img img{
    height: 195px;
  }

  .whatsUp__btn_result {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    /* border: 2px solid #be206b !important; */
    color: #ffffff !important;
    background: linear-gradient(to bottom, #46bd7b 0%, #279859 100%) !important;
    font-weight: 700 !important;
    text-decoration: none;
    border-radius: 8px;
    font-size: 14px;
}

.phone__btn_result {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  /* border: 2px solid #be206b !important; */
  color: #ffffff !important;
  background: linear-gradient(to bottom, #0888f4 0%, #064d8a 100%)!important;
  font-weight: 700 !important;
  text-decoration: none;
  border-radius: 8px;
  font-size: 14px;
}

.single_disc p{
  font-size: 14px;
  overflow-wrap: break-word;
  hyphens: auto;
}

.single-btn-setting{
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid_container {
  display: flex;
  margin-top: 40px;
  margin-bottom: 40px;
 
}
.grid_container > ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.grid_container > ul > li {
  display: inline;
  padding: 10px;
  cursor: pointer;
}

.grid_container > ul > li > img {
  max-width: 250px;
}


a.bread_tag {
  display: flex;
  align-items: center;
  color: #737373;
  text-decoration: none;
  font-size: 15px;
  font-weight: 600;
}

a.bread_tag:hover{
  color: #be206b;
}

.bread-rwo{
  margin-top: 20px;
}

.sign_btn_side{
  flex: 0.7;
}

.contact_foot{
  display:  flex;
  flex-direction:  column;
  align-items:  center;
  justify-content: center;
  padding: 40px 0;
}

.contact_foot > h2{
  margin: 20px;
  color: #484848;
}

.contact_foot_btnRow{
  display:  flex;
}

.with_ft_btn{
  width: 200px;
}

.rd_btn{
  padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.allLink_foot{
  display:  flex;
  align-items:  center;
  justify-content: center;
  padding: 40px 0;
  text-align: center;
  border-top: 1px solid #dcdcdc;
}

.allLink_foot > p{
  font-size: 20px;
  font-weight: 500;
  color: rgb(65, 65, 65);
}

.allLink_foot > p > a{
  font-weight: 600;
  color: #279859;
  text-decoration: none;
}