.header__container {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
  position: sticky;
  z-index: 100;
  top: 0;
  box-shadow: 0 2px 10px 2px #00000012;
}

.header__logo_sec {
  width: 30%;
}

.menu_item {
  color: #3c3c3c;
}

.menu_item:hover {
  color: #0e0e0e;
}

.header__logo__img img {
  width: 220px;
}

.header__btns {
  width: 70%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header__btn__login {
  padding-left: 10px;
  padding-right: 10px;
  font-size: 16px;
  font-weight: 600;
}

.header__btn__signup {
  padding-right: 30px;
  font-size: 16px;
  font-weight: 600;
}

.header__btn__post {
  padding: 0.84rem 1.14rem;
  font-size: 15px;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  border: 0;
  -webkit-border-radius: 0.125rem;
  border-radius: 4px;
  cursor: pointer;
  text-transform: uppercase;
  white-space: normal;
  word-wrap: break-word;
  background-color: #448bc6 !important;
  color: #fff !important;
  margin: 0.375rem 0;
}

/*  
.header__btn__post:hover {
    background-color: #3571A3!important;
    color: #fff!important;
} */

.WithStyles\(ForwardRef\(MenuItem\)\)-root-9:focus {
  background-color: #d4d4d4 !important;
}

.mn-txt span {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #5d5d5d !important;
}

.post_ads_menu{
  background-color: #214277 !important;
}


.post_ads_menu_book{
  background-color: #8b8b8b !important;
}


.logout {
  border-top: 1px solid #dedede !important;
}

.topBar-btn {
  margin: 0 !important;
}

.topBar-btn2{
  margin: 0 !important;
  margin-right: 10px !important;
}
.header_option > .MuiListItemIcon-root {
  min-width: 35px !important;
  color: #585858 !important;
}

.post_ads_menu > .MuiListItemIcon-root {
  min-width: 35px !important;
  color: #ffffff !important;
}

.post_ads_menu_book > .MuiListItemIcon-root {
  min-width: 35px !important;
  color: #ffffff !important;
}

.mn-txt2 span {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #ffffff !important;
}

.user_name {
  margin: 0 !important;
  font-size: 12px;
}

.avatar_flex{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
}


.primary__btn_hd {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
  /* padding-left: 30px !important;
  padding-right: 30px !important; */
  /* border: 2px solid #be206b !important; */
  color: #ffffff !important;
  background: linear-gradient(to bottom, #05d4a2 0%, #008464 100%) !important;
  font-weight: 600 !important;
}

.primaryblue__btn_hd {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
  /* padding-left: 30px !important;
  padding-right: 30px !important; */
  /* border: 2px solid #be206b !important; */
  color: #ffffff !important;
  background: linear-gradient(to bottom, #0888f4 0%, #064d8a 100%) !important;
  font-weight: 600 !important;
}