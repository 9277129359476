.dashboardOption{
    display: flex;
    padding: 15px 25px;
    background-color: #014377;
    color: white;
    cursor: pointer;
    align-items: center;
}

.dash-active,.dashboardOption:hover{ 
    background-color: #00599c;
}

.dashboardOption > .MuiSvgIcon-root{
    font-size: 20px;
}

.dashboardOption .mb-none > p{
    padding-left:  10px;
    font-size: 15px;
}