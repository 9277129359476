.container {
  padding-left: 13%;
  padding-right: 13%;
}
.cool-smooth {
  scroll-behavior: smooth;
}

.link {
  text-decoration: none;
  color: #064d8a;
  align-items: center;
}

.link:hover {
  color: #00562b;
}
.primary__btn_div {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  color: #ffffff !important;
  background: linear-gradient(to bottom, #0888f4 0%, #064d8a 100%) !important;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  margin-bottom: 1rem !important;
}
.primary__btn {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  color: #ffffff !important;
  background: linear-gradient(to bottom, #0888f4 0%, #064d8a 100%) !important;
  margin-bottom: 1rem !important;
  font-weight: 600 !important;
}

.primaryblue__btn {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  color: #ffffff !important;
  background: linear-gradient(to bottom, #0888f4 0%, #064d8a 100%) !important;
  margin-bottom: 1rem !important;
  font-weight: 600 !important;
}

.primary__btn_outline {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  border: 2px solid #be206b !important;
  color: #be206b !important;
  background: linear-gradient(to bottom, #ffffff 0%, #ffffff 100%) !important;
  /* margin-bottom: 1rem !important; */
  font-weight: 600 !important;
}

.secondary__btn {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  /* border: 2px solid #696969 !important; */
  color: #ffffff !important;
  background: linear-gradient(to bottom, #8b8b8b 0%, #696969 100%) !important;
  margin-bottom: 1rem !important;
  font-weight: 600 !important;
}

.whatsUp__btn {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  color: #ffffff !important;
  background: linear-gradient(to bottom, #46bd7b 0%, #279859 100%) !important;
  margin-bottom: 1rem !important;
  font-weight: 600 !important;
}

.remove__btn {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  border: 2px solid #f44336 !important;
  color: #f44336 !important;
  /* background: linear-gradient(to bottom, #f44336 0%, #f44336 100%) !important; */
  margin-bottom: 1rem !important;
  font-weight: 600 !important;
}

.tab__container {
  background-color: rgba(0, 0, 0, 0.03) !important;
  border-bottom: 1px solid rgb(0 0 0 / 8%) !important;
  box-shadow: none !important;
}

.tab__title {
  color: #0888f4 !important;
  font-weight: 600 !important;
}

.tab_body-bg {
  background-color: #ffffff !important;
}

.tab_body {
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.tab_col {
  display: flex;
  flex-direction: column;
}

.tab-Link {
  padding: 10px 0;
}

.full-with {
  width: 100% !important;
}
.imageUpload {
  display: flex;
}
.uploadedList {
}

.uploadedList ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-flex;
}

.uploadedList ul li {
  margin: 10px 10px 10px 0;
}

.uploadedList ul li img {
  object-fit: cover;
  width: 150px;
  height: 187px;
  margin: 0;
  padding: 0;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.05);
  position: relative;
  border-radius: 10px;
}

.fileUploader {
  width: 100% !important;
  text-align: center !important;
}
.fileContainer .uploadPictureContainer {
  width: 25% !important;
  margin: 1% !important;
  padding: 0px !important;
}

.fileContainer {
  box-shadow: 0px 0px 10px -2px rgb(0 0 0 / 15%) !important;
}

.mg-rg {
  margin-right: 10px !important;
}

.notfound {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

.notfound h1 {
  font-size: 100px;
  font-weight: 800;
  color: #f064ad;
}

.notfound p {
  font-size: 20px;
  font-weight: 500;
  color: #448bc6;
}

.d-none {
  display: none;
}

.btn-line {
  display: flex;
}

.term-page {
  margin-top: 40px;
  margin-bottom: 40px;
}

.term-title {
  font-size: 20px;
  color: #5f5f5f;
}

.term-sub {
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 600;
}

.term-txt {
  color: #484848;
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 10px;
}

table.dataTable thead th,
table.dataTable thead td {
  padding: 5px 10px !important;
  border-bottom: 1px solid #d4d4d4 !important;
  font-size: 14px !important;
}

table.dataTable tbody th,
table.dataTable tbody td {
  font-size: 14px !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  color: #fafafa !important;
  border: 1px solid #be206b !important;
  background-color: white !important;
  background: linear-gradient(to bottom, #be206b 0%, #be206b 100%) !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  padding: 5px 10px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}
.dataTables_wrapper .dataTables_info {
  font-size: 13px;
}

.slick-dots {
  bottom: 5px !important;
}

.slick-dots li button:before {
  color: #ffffff !important;
  font-size: 8px !important;
}

.slick-dots li {
  margin: 0 2px !important;
}

.paginate_row {
  display: flex;
  justify-content: flex-end;
  justify-content: center;
  padding: 20px 0;
}
.paginate_empty {
  display: none;
}

.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #008464;
  background-color: #fff;
  border: 1px solid #dee2e6;
  text-decoration: none;
  font-weight: 600;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #008464;
  border-color: #008464;
}

.contact-txt {
  font-size: 16px;
  color: #545454;
  line-height: 1.4;
  margin-bottom: 10px;
}

.cont-phone {
  text-decoration: none;
  color: #be206b;
  font-weight: 600;
}

.empty_area {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 30vh;
}

.empty_area > .MuiSvgIcon-root {
  font-size: 40px;
  color: rgb(150, 150, 150);
}

.empty_area > p {
  margin-top: 5px;
  font-size: 14px;
  color: rgb(150, 150, 150);
}

.MuiRadio-colorSecondary.Mui-checked {
  color: #008464 !important;
}

.css-dq0l7e {
  width: 100% !important;
}

.cate_style {
  margin-top: -23px;
  font-size: 30px;
  font-weight: 700;
  color: #b13f65;
  /* text-shadow: -5px 4px 0 #b13f6538; */
  text-shadow: -4px 3px #b13f6538, 0 5px #fff, 5px 0 #fff, 0 -5px #fff;
  letter-spacing: 3px;
}

.box_rowStyle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.night_style {
  margin-top: -23px;
  font-size: 20px;
  font-weight: 700;
  padding-right: 10px;
  color: rgb(114, 114, 114);
  text-shadow: -4px 3px #fff, 0 5px #fff, 5px 0 #fff, 0 -5px #fff;
}

.footer_text_section {
  margin: 20px 0;
}

.footer_text > p {
  font-size: 12px;
  color: #333;
  padding-bottom: 20px;
  text-align: justify;
  overflow-wrap: break-word;
  hyphens: auto;
}

.footer_text > h3 {
  border-top: 1px solid rgb(185, 185, 185);
  padding-top: 20px;
  font-size: 12px;
  color: #333;
  padding-bottom: 10px;
}

.styles_scroll-to-top__2A70v {
  padding: 10px;
  border-radius: 50% !important;
  box-shadow: 0 0 20px 0 rgb(0 0 0 / 28%) !important;
  right: 25px !important;
}

.ads_ft_bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e2e2e2;
  padding: 5px;
  background-color: whitesmoke;
  border-radius: 10px;
}

.mg_rm {
  margin: 0 !important;
}

.ads_ft_btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #484848;
  cursor: pointer;
  font-size: 12px;
  padding: 5px;
}

.ads_ft_btn:hover {
  background-color: #47a37c;
  color: white;
  border-radius: 5px;
}

.rw_ads_btn {
  width: 100%;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.multiSelectContainer li {
  padding: 5px;
  font-size: 14px;
}

.multiSelectContainer input {
  font-size: 16px;
  padding: 5px;
}

.service_list_tag > span {
  font-size: 12px;
  background-color: #2778c4;
  padding: 2px 7px;
  color: white;
  border-radius: 10px;
  margin-right: 5px;
  /* white-space: normal; */
}

.service_list_tag {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 25px;
  max-height: 50px;
  min-height: 50px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 5px;
}

.service_list_tag2 > li {
  font-size: 14px;
  background-color: #2778c4;
  padding: 5px 10px;
  color: white;
  border-radius: 10px;
  margin-right: 5px;
  margin-bottom: 5px;
  display: table;
  text-transform: uppercase;
  font-weight: 600;
}

.service_list_tag2 {
  margin-bottom: 10px;
  padding: 0px;
  list-style: none;
}

.fixedbutton {
  position: fixed;
  bottom: 10px;
  left: 10px;
  background: linear-gradient(to bottom, #128c7e 0%, #128c7e 100%) !important;
  width: 50px;
  height: 50px;
  border-radius: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  box-shadow: 2px 2px 15px -3px #128c7e;
}

.fixedbutton > a {
  color: white !important;
}

.fixedbutton > .MuiSvgIcon-root {
  font-size: 35px;
}

.ul_ff {
  margin-bottom: 10px;
  padding: 0;
  list-style: none;
}

.ul_ff > li {
  color: #484848;
  font-size: 16px;
  line-height: 1.5;
}

.packages_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  margin-top: 40px;
}

.package_box {
  flex: 0.32;

  background-color: #fff;
  border-radius: 10px;
}

.package_box_header {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #065da7;
  background: linear-gradient(to bottom, #0888f4 0%, #064d8a 100%);
  border-radius: 10px 10px 0 0;
  padding: 20px;
  color: white;
}

.package_box_header > p {
  font-size: 16px;
}

.package_box_header > h4 {
  font-size: 35px;
}

.package_box_header > span {
  font-size: 15px;
  font-weight: 500;
}

.package_box_body {
  padding: 20px;
  height: 160px;
}

.package_box_body > ul {
  list-style: none;
  padding: 0;
}

.package_box_body > ul > li {
  display: flex;
  /* align-items: center; */
  margin-bottom: 5px;
}

.package_box_body > ul > li > .MuiSvgIcon-root {
  font-size: 20px;
  color: #333;
  margin-right: 5px;
}
.package_box_body > ul > li > span {
  font-size: 14px;
  color: #333;
}

.package_box_footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dio_body {
  width: 500px;
}

.dio_amount {
  font-size: 16px;
  color: #131313;
  font-weight: 600;
  margin-bottom: 10px;
}

.booking_page {
  margin-top: 40px;
  padding-right: 20%;
  padding-left: 20%;
}

.field_sps {
  margin-bottom: 20px;
}

.contact_us {
  display: flex;
  margin-top: 20px;
  gap: 10px;
  width: 100%;
  
}

.contact_us > .box {
  background-color: white;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px -2px rgb(0 0 0 / 15%); 
}

.contact_us > .box > h3 {
  font-size: 16px;
  color: #131313;
  margin-bottom: 5px;
}

.contact_us > .box > p {
  font-size: 14px;
  color: #484848;
  margin-bottom: 10px;
}
