.login__container {
  display: flex;
  flex: 1;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

.login_box {
  width: 500px;
}

.login_title {
  margin-bottom: 40px;
}

.login_title h2 {
  color:#424242ed;
  font-weight: 600;
  font-size: 20px;
  text-transform: uppercase;
  margin-bottom: 6px;
}

.login__fields {
  margin-bottom: 20px;
}

.login__filds_sp {
  width: 100%;
}
.forget__pw {
  color: #008464;
  text-align: end;
  font-size: 14px;
  cursor: pointer;
}
.lg-btn {
  width: 100%;
}

.login_txt1 {
  color: #5d5d5d;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 5px;
}

.login_txt2 {
  color: #676767;
  font-size: 14px;
}

.signup_btn {
  margin-top: 10px;
  text-align: center;
  color: #5a5a5a;
}

.pv_txt {
  font-size: 12px;
  color: #737373;
  font-weight: 500;
}

.pv_mtxt {
  font-size: 14px;
  color: #737373;
  font-weight: 600;
}

.pv_check {
  margin-bottom: 20px;
}
.pv_check .MuiCheckbox-root {
  margin-top: -40px;
}

.pv_check .MuiTypography-body1 {
  line-height: 1.2 !important;
}

.back_url{
  color: rgb(59, 59, 59);
} 

.back_url > span {
  color:#008464;
  font-size: 16px;
  font-weight: 700;
}

.reset_text{
  font-size: 13px;
  color: #737373;
  margin-bottom: 10px;
}