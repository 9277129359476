.postads__container {
  display: flex;
  flex: 1;
  margin-top: 30px;
  justify-content: center;
}
.steps-bar {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.steps .stpe-box {
  font-size: 16px;
  background-color: #484848;
  color: white;
  width: 35px;
  height: 35px;
  text-align: center;
  border-radius: 50%;
}

.steps .stpe-box-active {
  font-size: 16px;
  background-color: #095ba2;
  color: white;
  width: 35px;
  height: 35px;
  text-align: center;
  border-radius: 50%;
}

.steps .stpe-box-active p {
  padding-top: 6px;
  font-weight: 700;
}

.steps .stpe-box p {
  padding-top: 6px;
  font-weight: 700;
}

.steps span {
  padding-left: 10px;
  font-size: 16px;
  color: #3a3a3a;
  font-weight: 600;
}

.steps {
  display: flex;
  align-items: center;
  z-index: 2;
  padding: 0 20px;
  background-color: whitesmoke;
}
.steps-line {
  position: absolute !important;
  width: 59%;
  height: 2px !important;
  margin: 0 !important;
  flex-shrink: 0 !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
  margin-top: 17px !important;
}
.post-ads-section {
  padding-right: 10%;
  padding-left: 10%;
}
.postads__step {
  width: 70%;
  margin-bottom: 20px;
}

.postads__body {
  margin-top: 15px;
  padding: 0 25px;
}

.fild_sp {
  margin-bottom: 20px;
}

.cal-2 {
  display: flex;
  justify-content: space-between;
}
.cal-2-1-with {
  width: 20%;
}

.cal-2-2-with {
  width: 76%;
}
.steps_one_section {
  margin-bottom: 35px;
  border: 1px solid whitesmoke;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0px 2px 3px 0px #00000026;
  background-color: #fff;
}

.steps_one_section .txt {
  font-size: 16px;
  font-weight: 600;
  color: #064d8a;
  background-color: whitesmoke;
  /* padding-bottom: 15px; */
  align-items: center;
  line-height: 2.5;
  margin-bottom: 20px;
  padding-left: 10px;
  border-radius: 6px;
}
.sub-dis {
  font-size: 16px;
  font-style: normal;
  text-transform: uppercase;
  background: url(../img/icon_watermark.png) no-repeat 0 50%;
  background-size: 40px 40px;
  padding-left: 55px;
  /* height: 40px; */
  height: auto;
  color: #666666;
}

.whatsapp-icon {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAADU0lEQVRIS6WWW2gcZRTH//8zG22wIhZpDYi1+iC0IqUifajZdImoYMEHSTSy+RIwna30RYW2Xl76UoWCIOJlsxF0d2htWqHgk1jiZiY+9KGCQvLSC/SSVIiCYO0tnfmOzNRpdrfbjWvmbc73P+d35pzznV2ixTPiD64T5YAIchphA2AfiOVK+V3JGUJ/7AjDbz7rPXjuTmHY7GBHdfgROLo/stHLQpFWSVi1FiLjHers+bznqwuN2tsAhcDkoSgCuKdV4MYzC/ztULcXs96h2rM6gBuYXVTsbydwo1apb5Wy3sep/Rbg38y95QRPfG3cIr5a7Ckfjl8TQFxzFTtdWxYCvgWqAn1RwafbAlt7KYKu/zJ3YDYBFHwzDqC/JshElz3/wt7cZOgG+U1U+bktQPIlemA05+UZjyJVT9dOi1p0l3KVn9KgBd8cB7C5TUikDh6lO2neI7Gv1vnKNV3pPe9dXgQMGUDLbQKgxG7uCMwxVTxbN1oZXVPc4s2nNtc3QwS+bhcA6vcsVM1FCLrqnTk02lOuxLY3AvNkGOG4CDrbBgCzLFTzCxDpqHPWaKZL5zYmTZ4YeoyZaBqQFW0DFAss+CYE4Nx2YRTvl7ZWPojtbmBGqBj7X4ARP/+bA3mwydUPaZFLp8n1zTsEPqzX2V+gsgrEw3eAzzZtco34DyvYMtZdOZlcyCmzTS2+APAQFCcdvfpUeO/913n5+oCqxtP4eB0obnLzzBZlqrioGT439kx5JrYOV4dX3C32JQ15otRbPpMq+w73OatW3/Ur6GxY9OYuxmsiYnim5Vq29hIdusVubxyENi2HgoVJM1czkZEDZ93NVVE1hyB4Zckmqv0BdPaNZstTjSDXH9xJ8NM0hhJeKVsxCWDnxGtrFzKZaQFWLglJFiZOkTwKa0+Q0gnVPhDbUl+r+MuJsL7YW5m7ta7dwJylYu1/AbTUWCgc9I9mK9/GugTgTg1upmW80Jb3WKiSb5a2lj9JA93sQWA+guLt5USPyyKC19PMFwEKupOD5yjsVOA7ERwl7akodPYqov6lfvQBREoclBt4N655Y5J0j7n3oePapj/nrwZH+o9EtYK4+TcymQGqzSnkCSVWi8KCmAd1GioTDmS82b+JNM4/izZctSJpbiEAAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  padding-left: 25px;
  height: 20px;
  /* padding-top: 4px; */
  /* padding-bottom: 2px; */
  /* margin-left: 2px; */
  background-size: 20px 20px;
  color: #666666;
  font-weight: 500;
  font-size: 14px;
  align-items: center;
}

.step2-subheader {
  font-size: 13px;
  font-weight: 600;
  color: #666666;
}
.step2-subheader2 {
  font-size: 13px;
  color: #666666;
}
.topicon-bar {
  display: flex;
  margin-top: 30px;
  align-items: center;
}
.topicon-bar-text {
  padding-left: 20px;
}

.topicon-bar-text h2 {
  line-height: 1;
  color: #333333;
}

.topicon-bar-text p {
  font-size: 14px;
  color: #666666;
}

.pack-section {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 30px;
}

.box-pack {
  margin: 0;
  border: 2px solid #b13f65;
  border-radius: 15px;
  text-align: center;
  width: 243px;
  height: 120px;
  margin-right: 10px;
  display: inline-flex;
  flex-direction: column;
  margin-bottom: 30px;
  cursor: pointer;
}

.box-pack img {
  width: 160px;
  margin-top: -30px;
}

.box-pack .box-pack-dis {
  font-size: 16px;
  color: #909090;
  font-weight: 600 !important;
}

.box-pack .box-pack-price {
  font-size: 20px;
  color: #666666;
  font-weight: 700;
}
.box-pack .box-pack-credit {
  font-size: 14px;
  color: #252525;
}
.night_icon {
  width: 60px !important;
}

.night-box {
  border: 2px solid #3d88c5 !important;
}

.premium-bar {
  display: flex;
  margin-top: 30px;
  align-items: center;
}
.premium-bar-img {
  width: 20%;
}
.premium-bar-img img {
  width: 100%;
}
.premium-bar-text {
  padding-left: 10px;
  width: 50%;
  padding-right: 10px;
}

.premium-bar-text .txt1 {
  font-size: 14px;
  font-weight: 600;
  color: #666666;
}
.premium-bar-text .txt2 {
  font-size: 12px;
  color: #737373;
}
.premium-bar-btn {
  width: 30%;
  border: 2px solid #249b2f;
  background: url(../../Assets/img/selected_icon_green.png) no-repeat;
  background-position: right -2px;
  cursor: pointer;
  background-size: 40px;
  text-align: center;
  padding: 15px 0;
  border-radius: 10px;
}

.premium-bar-btn .price {
  font-size: 14px;
  color: #484848;
}
.premium-bar-btn .price span {
  font-size: 16px;
  font-weight: 700;
  color: #484848;
}

.premium-bar-btn .credit {
  font-size: 12px;
  color: #484848;
}

.schedule-box {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 20px;
}

.schedule-box-li {
  display: inline-flex;
  border: 2px solid #b74268;
  margin-right: 10px;
  padding: 10px 20px;
  border-radius: 10px;
  margin-bottom: 15px;
  cursor: pointer;
}

.schedule-box li p {
  font-size: 16px;
  font-weight: 500;
}

.active-style {
  border: 2px solid #249b2f !important;
  background: url(../../Assets/img/selected_icon_green.png) no-repeat !important;
  background-position: right -2px !important;
  cursor: pointer !important;
  background-size: 40px !important;
}

.call-icon {
  color: #bc436a;
  font-size: 14px;
  margin-right: 10px;
  font-weight: 600;
  text-decoration: none;
}

.call-icon span {
  margin-left: 7px;
}

.btn-sp {
  margin-right: 10px !important;
}

.total-priceBar p {
  font-size: 30px;
  font-weight: 700;
  color: #be206b;
  text-align: right;
}

.basket-box {
  display: flex;
  flex-direction: column;
  border: 2px solid #cecece;
  border-radius: 10px;
  margin-bottom: 20px;
}

.basket-title {
  display: flex;
  align-items: center;
  background-color: #e8e8e8;
  border-radius: 10px 10px 0 0;
  padding: 5px 15px;
}

.cart-icon img {
  width: 40px;
}

.basket-txt p {
  margin-left: 15px;
  font-size: 16px;
  font-weight: 600;
  color: #484848;
}

.packge-item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;
  padding: 15px 15px;
}
.top-icon {
  width: 15%;
}
.top-icon img {
  width: 80px;
}
.item-description {
  width: 50%;
}
.item-description p {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 600;
}
.item-description span {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.87);
}
.item-price {
  width: 35%;
  text-align: end;
}

.item-price p {
  font-size: 18px;
  color: #008464;
  font-weight: 600;
}

.tot-item {
  display: flex;
  align-items: center;
  padding: 15px 15px;
}

.tot-description {
  width: 65%;
  text-align: end;
}

.tot-description p {
  color: #666666;
  font-size: 20px;
}

.tot-price {
  width: 35%;
  text-align: end;
}

.tot-price p {
  font-size: 20px;
  color: rgba(30, 30, 30, 0.93);
  font-weight: 700;
}

.buy-credits-row {
  display: flex;
  align-items: center;
  padding: 10px 10px;
  cursor: pointer;
}
.imgc-icon img {
  width: 35px;
}

.txt-credit {
  margin-left: 20px;
}

.txt-credit .txt1 {
  font-size: 16px;
  font-weight: 600;
  color: rgba(30, 30, 30, 0.93);
}

.txt-credit .txt2 {
  font-size: 14px;
  font-weight: 600;
  color: #be206b;
}

.btn-buy {
  margin-left: 20px;
}

.btn-mg-remove {
  margin: 0px !important;
}

.option-row {
  display: flex;
  align-items: center;
  padding: 10px 20px;
}
.card-icon {
  margin-right: 5px;
}
.card-icon img {
  width: 50px;
}

.cctx {
  font-size: 20px;
  font-weight: 600;
  color: #484848;
}

.paymnet-responce {
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-bottom: 40px;
}
.done-icon {
  color: #199a71;
  font-size: 100px !important;
}
.done-txt h1 {
  color: #199a71;
}
.done-desc p {
  padding: 5px 0px 30px 0px;
  font-size: 16px;
  color: #484848;
  font-weight: 600;
}
.done-btn {
  width: 200px !important;
}
.btn-don-row {
  display: flex;
  text-align: center;
  justify-content: center;
}

.fail-icon {
  color: #e44242;
  font-size: 100px !important;
}
.fail-txt h1 {
  color: #e44242;
}
.up-img-list {
  /* text-align: center; */
}

.up-img-list ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.up-img-list ul li {
  width: 170px;
  height: 200px;
  display: inline-flex;
  margin: 10px;
  cursor: pointer;
}

.up-img-list ul li img {
  width: 170px;
  height: 200px;
  object-fit: cover;
}

.pr-box {
  display: grid;
}
.pr-lable {
  color: white;
  background-color: #be206b;
  margin-top: -30px;
  z-index: 1;
  text-align: center;
}

.pr-lable p {
  font-size: 14px;
  padding: 4px;
  font-weight: 600;
}
.up-image-dis {
  display: flex;
  padding: 5px;
  font-size: 14px;
  font-weight: 600;
  color: #666666;
  justify-content: space-between;
}

.des_txt {
  font-size: 16px;
  color: gray;
  margin-bottom: 6px;
}

.back_page {
  display: inline-flex;
  align-items: center;
  margin: 10px 5px;
  cursor: pointer;
  background-color: whitesmoke;
  padding: 10px 15px;
  border-radius: 5px;
  box-shadow: 0 2px 2px 0px #00000026;
}

.back_page > p {
  font-size: 14px;
  color: #333;
  padding-left: 5px;
}

.back_page > .MuiSvgIcon-root {
  font-size: 20px;
  color: #333;
}


.alert_row{
  margin : 20px 0px;
}

.dis_fl{
  display: flex;
}